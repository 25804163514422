"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.confirm = exports.setupEphemeralModal = exports.createModal = void 0;
const dom_1 = require("#assets/utils/dom");
const MODAL_TEMPLATE = (0, dom_1.createTemplate)(`
<div class="modal fade">
  <div class="modal-dialog modal-dialog-centered">
    <article class="modal-content">
      <section class="modal-body">
        {{content}}
      </section>
    </article>
  </div>
</div>
`);
const MODAL_TITLED_TEMPLATE = (0, dom_1.createTemplate)(`
<div class="modal fade">
  <div class="modal-dialog modal-dialog-centered">
    <article class="modal-content">
      <header class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </header>
      <section class="modal-body">
        {{content}}
      </section>
    </article>
  </div>
</div>
`);
const CONFIRM_MODAL_TEMPLATE = (0, dom_1.createTemplate)(`
<div class="modal fade">
  <div class="modal-dialog">
    <article class="modal-content">
      <section class="modal-body">{{message}}</section>
      <footer class="modal-footer">
        <button type="button" class="btn btn-{{colorNo}}" data-bs-dismiss="modal">
          {{labelNo}}
        </button>
        <button type="button" class="btn btn-{{colorYes}}">
          {{labelYes}}
        </button>
      </footer>
    </article>
  </div>
</div>
`);
const CONFIRM_TITLED_MODAL_TEMPLATE = (0, dom_1.createTemplate)(`
<div class="modal fade">
  <div class="modal-dialog">
    <article class="modal-content">
      <header class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
      </header>
      <section class="modal-body">{{message}}</section>
      <footer class="modal-footer">
        <button type="button" class="btn btn-{{colorNo}}" data-bs-dismiss="modal">
          {{labelNo}}
        </button>
        <button type="button" class="btn btn-{{colorYes}}">
          {{labelYes}}
        </button>
      </footer>
    </article>
  </div>
</div>
`);
function createModal(content = '', opts = {}) {
    return opts.title
        ? (0, dom_1.createModel)(MODAL_TITLED_TEMPLATE, Object.assign(Object.assign({}, opts), { content }))
        : (0, dom_1.createModel)(MODAL_TEMPLATE, Object.assign(Object.assign({}, opts), { content }));
}
exports.createModal = createModal;
function setupEphemeralModal(modal) {
    $(modal).on('hidden.bs.modal', () => {
        modal.remove();
    });
    document.body.appendChild(modal);
    // @ts-ignore Bootstrap jQuery methods
    $(modal).modal('show');
    return modal;
}
exports.setupEphemeralModal = setupEphemeralModal;
function confirm(message, callback, opts = {}) {
    const { title = '', colorYes = 'success', colorNo = 'secondary', labelYes = '<span class="fal fa-check mr-1"></span> Oui', labelNo = '<span class="fal fa-undo-alt mr-1"></span> Non' } = opts;
    const template = title ? CONFIRM_TITLED_MODAL_TEMPLATE : CONFIRM_MODAL_TEMPLATE;
    const modal = (0, dom_1.createModel)(template, { message, title, colorYes, colorNo, labelYes, labelNo });
    modal.querySelector('.modal-footer button:first-child').addEventListener('click', () => {
        // @ts-ignore Bootstrap jQuery methods
        $(modal).modal('hide');
    });
    modal.querySelector('.modal-footer button:last-child').addEventListener('click', () => {
        const result = callback === null || callback === void 0 ? void 0 : callback();
        if (result instanceof Promise) {
            result.then(() => {
                // @ts-ignore Bootstrap jQuery methods
                $(modal).modal('hide');
            });
        }
        else {
            // @ts-ignore Bootstrap jQuery methods
            $(modal).modal('hide');
        }
    });
    return setupEphemeralModal(modal);
}
exports.confirm = confirm;
