"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupTinyMce = exports.TINYMCE_LANGUAGE_CHECKER = exports.TINYMCE_LANGUAGES = exports.TINYMCE_DEFAULT_HEIGHT = void 0;
// @ts-ignore loaded from script tag
const { tinymce } = window;
// Tips to colorize css template strings (cf. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals)
const css = (strings, ...values) => strings.reduce((accu, str, idx) => `${accu}${str}${values[idx] || ''}`, '');
exports.TINYMCE_DEFAULT_HEIGHT = 650;
exports.TINYMCE_LANGUAGES = 'fr_FR';
exports.TINYMCE_LANGUAGE_CHECKER = 'fr';
const AUTOCOMPLETE_LIST_TEMPLATE = document.createElement('template');
AUTOCOMPLETE_LIST_TEMPLATE.innerHTML = `
<div class="dropdown">
  <a href="#!" class="btn btn-silae" data-toggle="dropdown" aria-expanded="false">
    Insérer un mot clé
  </a>
  <div class="dropdown-menu dropdown-menu-right">
    <input class="form-control" placeholder="Rechercher..." />
    <div class="dropdown-list" style="max-height:23rem;overflow-y:auto">
    </div>
  </div>
</div>
`;
const ADDITIONAL_STYLES = css `
  body {
    background: #fff;
    font-size: 14px;
  }

  .keyword {
    display: inline;
    padding: .25em .4em;
    background-color: #f8f9fa;
    border-radius: .25rem;
    font-size: .85em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
  }
`;
function setupTinyMce(container, textarea, opts) {
    const { height = exports.TINYMCE_DEFAULT_HEIGHT } = opts || {};
    return new Promise((resolve) => {
        tinymce.init({
            content_style: ADDITIONAL_STYLES,
            language: exports.TINYMCE_LANGUAGES,
            target: textarea,
            font_size_formats: "6px 7px 8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 24px 36px 48px 6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 20pt 24pt 36pt 48pt ",
            fullscreen_native: true,
            height,
            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tableofcontents footnotes mergetags autocorrect typography inlinecss fullscreen',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen',
            toolbar_sticky: true,
            spellchecker_language: exports.TINYMCE_LANGUAGE_CHECKER,
            image_title: true,
            /* enable automatic uploads of images represented by blob or data URIs*/
            automatic_uploads: true,
            /*
              URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
              images_upload_url: 'postAcceptor.php',
              here we add custom filepicker only to Image dialog
            */
            file_picker_types: 'image',
            /* and here's our custom image picker*/
            file_picker_callback: function (callback) {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                /*
                  Note: In modern browsers input[type="file"] is functional without
                  even adding it to the DOM, but that might not be the case in some older
                  or quirky browsers like IE, so you might want to add it to the DOM
                  just in case, and visually hide it. And do not forget do remove it
                  once you do not need it anymore.
                */
                input.onchange = function () {
                    const reader = new FileReader();
                    const file = input.files[0];
                    reader.addEventListener('load', () => {
                        /*
                          Note: Now we need to register the blob in TinyMCEs image blob
                          registry. In the next release this part hopefully won't be
                          necessary, as we are looking to handle it internally.
                        */
                        const id = 'blobid' + Date.now();
                        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        const base64 = reader.result.toString().split(',')[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        /* call the callback and populate the Title field with the file name */
                        callback(blobInfo.blobUri(), { title: file.name });
                    });
                    reader.readAsDataURL(file);
                };
                input.click();
            },
            setup: (editor) => {
                if (textarea.dataset.keywords) {
                    const keywordOpts = JSON.parse(textarea.dataset.keywords);
                    setupKeywordAutocomplete(container, textarea, editor, keywordOpts);
                }
                resolve(editor);
            },
        });
    });
}
exports.setupTinyMce = setupTinyMce;
function setupKeywordAutocomplete(container, textarea, editor, opts) {
    if (!opts)
        return;
    let props = null;
    function createKeywordTag(value, label) {
        if (!props)
            return value;
        if (value.startsWith('BLOC')) {
            if (!label) {
                label = props.dict[value];
            }
            if (!label) {
                // Fix for number attached or not to text
                // - BLOC_COMPOSANT1 => BLOC_COMPOSANT_1
                // - BLOC_CONTRACT39H_BIS => BLOC_CONTRACT_39H_BIS
                label = props.dict[value.replace(/([A-Z])(\d)/, '$1_$2')];
            }
            return `${props.token_start}${label || value}${props.token_end}`;
        }
        return `${props.token_start}${value}${props.token_end}`;
    }
    editor.setupKeywordContent = function (content) {
        const matches = Array.from(content.matchAll(/#([\w ]+)#/g));
        for (let idx = matches.length - 1; idx >= 0; idx--) {
            const match = matches[idx];
            const before = content.slice(match.index - props.token_start.length + 1, match.index + 1);
            if (before === props.token_start)
                continue;
            const start = match.index;
            const end = start + match[0].length;
            content = content.slice(0, start) + createKeywordTag(match[1]) + content.slice(end);
        }
        return content;
    };
    editor.revertKeywordContent = function (content) {
        const matches = Array.from(content.matchAll(/#([\w ]+)#/g));
        for (let idx = matches.length - 1; idx >= 0; idx--) {
            const match = matches[idx];
            const before = content.slice(match.index - props.token_start.length + 1, match.index + 1);
            if (before !== props.token_start)
                continue;
            const start = match.index - (props.token_start.length - 1);
            const end = start + props.token_start.length + match[1].length + props.token_end.length;
            let value = match[0];
            if (props.rdict[match[1]])
                value = `#${props.rdict[match[1]]}#`;
            content = content.slice(0, start) + value + content.slice(end);
        }
        return content;
    };
    const setupAutocomplete = () => {
        editor.ui.registry.addAutocompleter('keywords', {
            trigger: opts.trigger_token,
            minChars: 1,
            columns: 'auto',
            onAction(autocompleteApi, rng, value) {
                editor.selection.setRng(rng);
                editor.insertContent(createKeywordTag(value));
                autocompleteApi.hide();
            },
            fetch(pattern) {
                pattern = pattern.toLowerCase();
                const results = props.keywords
                    .filter((keyword) => {
                    if (!keyword.is_active)
                        return false;
                    if (!keyword.value)
                        return false;
                    return 0 <= keyword.value.toLowerCase().indexOf(pattern);
                })
                    .map((keyword) => ({
                    type: 'cardmenuitem',
                    text: keyword.text,
                    value: keyword.value,
                    items: [{
                            type: 'cardcontainer',
                            direction: 'vertical',
                            items: [{
                                    type: 'cardtext',
                                    text: keyword.text,
                                    name: 'keyword_value',
                                    classes: ['font-weight-bold', 'mb-2'],
                                }, {
                                    type: 'cardtext',
                                    text: keyword.value,
                                    name: 'char_name',
                                    classes: ['text-muted', 'font-italic'],
                                }]
                        }]
                }));
                return Promise.resolve(results);
            }
        });
    };
    const setupList = () => {
        const template = AUTOCOMPLETE_LIST_TEMPLATE.content.cloneNode(true);
        const dropdown = template.querySelector('.dropdown');
        const list = dropdown.querySelector('.dropdown-list');
        const toggle = dropdown.querySelector('[data-toggle="dropdown"]');
        const placeToAddAutocompleteListTemplate = container.querySelector('[data-placeholder="btn-autocomplete-keyword"]');
        if (placeToAddAutocompleteListTemplate) {
            placeToAddAutocompleteListTemplate.appendChild(dropdown);
        }
        else {
            container.appendChild(dropdown);
        }
        function createItem(text, value) {
            const item = document.createElement('a');
            list.appendChild(item);
            item.href = '#!';
            item.classList.add('dropdown-item');
            item.innerHTML = `<b class="pb-2">${value}</b><br/><em class="text-muted">${text}</em>`;
            item.addEventListener('click', (evt) => {
                evt.preventDefault();
                editor.insertContent(createKeywordTag(value));
                // @ts-ignore bootstrap jquery method
                $(toggle).dropdown('hide');
            });
        }
        for (let idx = 0; idx < props.keywords.length; idx++) {
            const { is_active, text, value } = props.keywords[idx];
            if (is_active)
                createItem(text, value);
        }
        dropdown.querySelector('input').addEventListener('keyup', (evt) => {
            const query = evt.target.value.toLowerCase();
            list.innerHTML = '';
            for (let idx = 0; idx < props.keywords.length; idx++) {
                const { is_active, text, value } = props.keywords[idx];
                if (is_active && (!query.length || text.toLowerCase().indexOf(query) !== -1 || value.toLowerCase().indexOf(query) !== -1)) {
                    createItem(text, value);
                }
            }
        });
    };
    return fetch(opts.url, {
        credentials: 'same-origin',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then((response) => {
        if (!response.ok)
            throw new Error(response.statusText);
        return response.json();
    }).then((resp) => {
        const dict = resp.keywords.reduce((accu, keyword) => (Object.assign(Object.assign({}, accu), { [keyword.value]: keyword.text })), {});
        const rdict = resp.keywords.reduce((accu, keyword) => (Object.assign(Object.assign({}, accu), { [keyword.text]: keyword.value })), {});
        props = Object.assign(Object.assign({}, resp), { dict, rdict });
        editor.setContent(editor.setupKeywordContent(editor.getContent()));
        setupAutocomplete();
        setupList();
    });
}
