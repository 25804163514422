"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectValue = exports.getSelectValue = void 0;
const getSelectValue = (select) => {
    const format = (value) => Number.isNaN(Number.parseFloat(value)) ? value : Number(value);
    if (!select.multiple) {
        if (!select.value)
            return;
        const opt = select.querySelector('option[selected]');
        if (!opt)
            return;
        return { label: opt.innerText, value: format(select.value) };
    }
    return Array.from(select.querySelectorAll('option[selected]')).map((opt) => {
        return { label: opt.innerText, value: format(opt.value) };
    });
};
exports.getSelectValue = getSelectValue;
const setSelectValue = (select, value) => {
    function closure(_value) {
        const value = typeof _value === 'object' ? String(_value.value) : String(_value);
        let opt = select.querySelector(`option[value="${value}"]`);
        if (!opt) {
            opt = document.createElement('option');
            opt.innerHTML = value;
            opt.value = value;
            select.append(opt);
        }
        opt.setAttribute('selected', 'selected');
    }
    select.querySelectorAll(`option[selected]`).forEach((opt) => opt.removeAttribute('selected'));
    if (select.multiple) {
        value.forEach((value) => closure(value));
    }
    else {
        closure(value);
    }
    select.dispatchEvent(new Event('change'));
    return value;
};
exports.setSelectValue = setSelectValue;
