"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScrollbarWidth = exports.queryDataTargetSelector = exports.queryDataTargetSelectors = exports.queryDataSelector = exports.queryDataSelectors = exports.parseDataTargetSelector = exports.parseDataTargetSelectors = exports.parseDataSelector = exports.parseDataSelectors = exports.getIndex = exports.generatePath = exports.createNode = exports.createModel = exports.createTemplate = exports.setupTemplate = void 0;
function setupTemplate(element, template, params = {}) {
    element.appendChild(template.content.cloneNode(true));
    for (let keys = Object.keys(params), idx = 0; idx < keys.length; idx++) {
        const key = keys[idx];
        const value = params[key];
        element.innerHTML = element.innerHTML.replace(new RegExp(`\{\{${key}}}`, 'g'), value);
    }
    return element;
}
exports.setupTemplate = setupTemplate;
function createTemplate(content) {
    const template = document.createElement('template');
    template.innerHTML = content.replace(/>[\n ] *</g, '><');
    return template;
}
exports.createTemplate = createTemplate;
function createModel(template, params = {}) {
    const div = document.createElement('div');
    setupTemplate(div, template, params);
    return div.firstElementChild;
}
exports.createModel = createModel;
function createNode(html, params = {}) {
    const template = createTemplate(html);
    return createModel(template, params);
}
exports.createNode = createNode;
const generatePath = (element, reference) => {
    if (!element || !(element instanceof Element))
        return;
    if (reference && reference === element)
        return;
    let path = [];
    while ((element === null || element === void 0 ? void 0 : element.nodeType) === Node.ELEMENT_NODE && element !== reference) {
        let selector = element.nodeName.toLowerCase();
        if (element.id)
            selector += `#${element.id}`;
        else
            selector += `:nth-child(${(0, exports.getIndex)(element)})`;
        path.unshift(selector);
        element = element.parentNode;
    }
    return path.join(" > ");
};
exports.generatePath = generatePath;
const getIndex = (element) => {
    let sibling = element;
    let nth = 0;
    do {
        if (sibling.nodeType === Node.ELEMENT_NODE)
            nth++;
        sibling = sibling.previousSibling;
    } while (sibling);
    return nth;
};
exports.getIndex = getIndex;
const parseDataSelectors = (selector, name) => {
    return selector.split(',').map((selector) => {
        return (0, exports.parseDataSelector)(selector, name);
    });
};
exports.parseDataSelectors = parseDataSelectors;
const parseDataSelector = (selector, name) => {
    selector = selector.trim();
    if (!selector.match(/^[\w\d]/))
        return selector;
    return `[${name}~="${selector}"]`;
};
exports.parseDataSelector = parseDataSelector;
const parseDataTargetSelectors = (selector, name) => {
    return (0, exports.parseDataSelectors)(selector, `data-${name}-target`);
};
exports.parseDataTargetSelectors = parseDataTargetSelectors;
const parseDataTargetSelector = (selector, name) => {
    return (0, exports.parseDataSelector)(selector, `data-${name}-target`);
};
exports.parseDataTargetSelector = parseDataTargetSelector;
const queryDataSelectors = (selector, name, referer = document.documentElement) => {
    return referer.querySelectorAll((0, exports.parseDataSelectors)(selector, name).join(','));
};
exports.queryDataSelectors = queryDataSelectors;
const queryDataSelector = (selector, name, referer = document.documentElement) => {
    if (!selector)
        return null;
    return referer.querySelector((0, exports.parseDataSelector)(selector, name));
};
exports.queryDataSelector = queryDataSelector;
const queryDataTargetSelectors = (selector, name, referer = document.documentElement) => {
    return (0, exports.queryDataSelectors)(selector, `data-${name}-target`, referer);
};
exports.queryDataTargetSelectors = queryDataTargetSelectors;
const queryDataTargetSelector = (selector, name, referer = document.documentElement) => {
    return (0, exports.queryDataSelector)(selector, `data-${name}-target`, referer);
};
exports.queryDataTargetSelector = queryDataTargetSelector;
function getScrollbarWidth() {
    var _a;
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    // @ts-ignore unknown specific msOverflowStyle
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);
    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);
    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
    // Removing temporary elements from the DOM
    (_a = outer.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(outer);
    return scrollbarWidth;
}
exports.getScrollbarWidth = getScrollbarWidth;
