"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.throttle = exports.debounce = exports.wait = void 0;
function wait(isReady, interval) {
    return new Promise((resolve) => {
        if (isReady())
            return resolve();
        (function wait() {
            setTimeout(() => {
                if (isReady())
                    resolve();
                else
                    wait();
            }, interval);
        })();
    });
}
exports.wait = wait;
function debounce(callback, delay = 300) {
    let timeout = 0;
    return function trigger(...args) {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            callback(...args);
        }, delay);
    };
}
exports.debounce = debounce;
function throttle(callback, delay = 300) {
    let previousArgs = null;
    let timeout = 0;
    return function trigger(...args) {
        if (timeout) {
            previousArgs = args;
            return;
        }
        previousArgs = null;
        callback(...args);
        timeout = window.setTimeout(() => {
            timeout = 0;
            if (previousArgs) {
                const tmpArgs = previousArgs;
                previousArgs = null;
                trigger(...tmpArgs);
            }
        }, delay);
    };
}
exports.throttle = throttle;
